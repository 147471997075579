import { useState, useEffect, useRef, useMemo } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import styles from './MobileHeader.module.scss';
import { constants } from '@/src/utils/constants';
import useHeader from './useHeader';
import Authentication from '@/components/Authentication';
import Omnisearch from "@/containers/homePage/Omnisearch";
import LogoBirds from './LogoBirds';
import LogoBirdsText from './LogoBirdsText';
import MenuSymb from './MenuSymb';
import AuthSymb from './AuthSymb';
import CloseSymb from './CloseSymb';
import * as menuSymbols from './MobileMenuSymbols';
import Portal from '@/components/common/Portal';
import CurrencyBox from '@/components/Currency/components/CurrencyMobile/components/CurrencyBox';
import useEmailConfPopup from '../EmailConfPopup/useEmailConfPopup';
import EmailConfPopup from '../EmailConfPopup/EmailConfPopup';
import { useRouter } from 'next/router';
import SwitcherComponent from '@/containers/Dashboard/dashboard-components/SideNav/SwitcherComponent';
import { GeneralMobileSwitcherRoutes } from './GeneralMobileSwitcherRoutes';
import { useEnvironment } from '@/providers/Environment';
import { Environments } from '@/static/contants';
import useAccountWidget from '@/containers/Dashboard/hooks/useAccountWidget';
import useEntity from '@/containers/Dashboard/hooks/useEntity';
import { PROFILE } from '@/redux/reducers/dashboardReducer';
import useBootstrap from '@/containers/newGeoPages/BootstrapContext';
import { getOmniInitializer } from '@/containers/newGeoPages/utils';
import Drawer from '@mui/material/Drawer';
import { useAuthentication } from '@/providers/Authentication';
import { AuthenticationPromptType } from "@/utils/customHooks/useAuth";
import { useCurrencyContext } from '@/components/Currency';
import { useMobileMenuContext } from './MobileMenuProvider';
import { Box, Slide } from '@mui/material';

const rootStyles = [
    styles.menu_open_body,
    'ReactModal__Body--open'
];

const MobileHeader = () => {
    const cnEnvironment = useEnvironment();
    const [
        authState,
        lists,
        currencies,
        handleLogout,
        pathLast,
        pathFirst,
        logoColored,
        setLogoColored,
        determineColor,
        positionAbs,
        positionFixed,
        blog
    ] = useHeader({ isMobile: true });

    const {
        isOpen: menuOpen,
        toggle
    } = useMobileMenuContext();

    const {
        isAuthPromptOpen: authOpen,
        setAuthState
    } = useAuthentication();

    const {
        isOpen: isCurrencyScreenOpen,
        open: openCurrencySelectionScreen,
        setOpen: setOpenCurrencySelectionScreen
    } = useCurrencyContext();

    const typeOfModal = useRef('login');
    const [headerSticky, setHeaderSticky] = useState(false);
    const refMenu = useRef(null);
    const refBand = useRef(null);
    const router = useRouter();

    useAccountWidget();
    const { profile, isLoaded } = useEntity(PROFILE);

    const geoBootstrapData = useBootstrap();
    const onInitOmnisearch = useMemo(
        () => (Object.keys(geoBootstrapData).length
            ? getOmniInitializer(geoBootstrapData)
            : null),
        [geoBootstrapData]);

    const stickyQuery = useMemo(
        () => {
            if ([''].includes(pathLast)) {
                return '.cn__hp__hero';
            } else if (['a', 't', 'l'].includes(pathFirst)) {
                return '.cn__image-picture.hero';
            } else if (['crypto'].includes(pathFirst)) {
                return 'main > section';
            } else {
                return null;
            }
        },
        [pathFirst, pathLast]
    );

    const hasOmni = useMemo(
        () => ([''].includes(pathLast) || ['a', 't', 'l'].includes(pathFirst)),
        [pathFirst, pathLast]
    );

    const logo = (
        <div
            className={clsx(headerSticky ? styles.logo_birds : styles.logo_text,
                !logoColored && styles.logo_white)}
        >
            {(headerSticky && hasOmni) ? <LogoBirds /> : <LogoBirdsText />}
        </div>
    );

    const { showConf, setShowConf } = useEmailConfPopup();

    useEffect(() => {
        if (stickyQuery) {
            const stickyY = document.querySelector(stickyQuery)?.scrollHeight;
            const stickyHandler = () => {
                setHeaderSticky(window.scrollY > stickyY);
            };
            window.addEventListener('scroll', stickyHandler);
            return () => window.removeEventListener('scroll', stickyHandler);
        }
    }, []);

    useEffect(() => {
        if (['crypto'].includes(pathFirst)) {
            return;
        }

        if (stickyQuery) {
            setLogoColored(headerSticky);
        }
    }, [headerSticky]);

    useEffect(() => {
        if (headerSticky) {
            refBand.current.classList.add(styles.band_sticky_hp_anim);
            setTimeout(() => {
                refBand.current?.classList.add(styles.band_sticky_normal);
                refBand.current?.classList.remove(styles.band_sticky_hp_anim);
            }, 400);
        } else {
            refBand.current.classList.remove(styles.band_sticky_anim);
            refBand.current.classList.remove(styles.band_sticky_normal);
        }
    }, [headerSticky]);

    useEffect(() => {
        if (menuOpen) {
            setLogoColored(true);
            // document.body.classList.add(styles.menu_open_body);
            // refMenu.current?.classList.add(styles.menu_open);
            // const timeoutID = setTimeout(() => refMenu.current?.classList.add(styles.menu_open_anim), 10);
            // return () => {
            //     clearTimeout(timeoutID);
            // };
        } else {
            // refMenu.current?.classList.remove(styles.menu_open);
            // refMenu.current?.classList.remove(styles.menu_open_anim);
            // document.body.classList.remove(styles.menu_open_body);
            if (!headerSticky && positionAbs) {
                setLogoColored(determineColor());
            }
        }
    }, [menuOpen]);

    useEffect(() => {
        if (authOpen || menuOpen) {
            document.querySelector(".mobile-tab-action")?.classList.add(styles.hide);
            document.body.classList.add(...rootStyles);
        } else {
            document.querySelector(".mobile-tab-action")?.classList.remove(styles.hide);
            document.body.classList.remove(...rootStyles);
        }
    }, [authOpen, menuOpen]);

    useEffect(() => {
        if (['hotel', 'listings'].includes(pathFirst)) {
            if (menuOpen) {
                refBand.current?.classList.add(styles.ldp_band);
                refBand.current?.classList.add(styles.ldp_band_margin);
            } else {
                refBand.current?.classList.remove(styles.ldp_band);
                setTimeout(() => refBand.current?.classList.remove(styles.ldp_band_margin), 400)
            }
        }
    }, [menuOpen]);
    const [isIosDevice , setIsIosDevice] = useState(false)
    useEffect(()=>{
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsIosDevice(true) ;
        }
    },[])

    useEffect(() => {
        if (!authState && router?.asPath?.includes("redirect_url")) {
            setAuthState({
                isAuthPromptOpen: true,
                authPromptType: AuthenticationPromptType.Login
            });
        }
    }, []);

    return (
        <div className={clsx('MobileHeader_wrapper',
            styles.wrapper,
            positionAbs && styles.abs,
            positionFixed && styles.fixed_pos)}
        >
            <div
                ref={refBand}
                className={clsx(styles.band,
                    positionAbs && styles.abs,
                    headerSticky && styles.band_sticky_hp,
                    (pathLast === 'sr') && styles.sr,
                    (pathLast === '') && styles.hp,
                    (['a', 'l'].includes(pathFirst)) && styles.hp,
                    (['hotel', 'listings'].includes(pathFirst)) && styles.ldp)}
            >
                <div className={clsx(styles.main)}>
                    {(pathLast === '')
                        ? <div>{logo}</div>
                        : <Link href={'/'}>
                            <a className={clsx(styles.logo,
                                ((['reservation',].includes(pathFirst)) && !menuOpen) && styles.logo_invis)}>
                                {logo}
                            </a>
                        </Link>}

                    {(hasOmni && headerSticky && !menuOpen) && (
                        <Omnisearch
                            className='omnisearch_header_embedded'
                            withRecentSearches={false}
                            withActivityInterests={false}
                            withLocationsNearby={false}
                            onInit={onInitOmnisearch}
                            onMobileOmnisearchOpen={onInitOmnisearch}
                        />
                    )}

                    <div
                        className={clsx(styles.menu_symb, !logoColored && styles.menu_symb_white)}
                        onClick={toggle}
                    >
                        {menuOpen ? <CloseSymb /> : <MenuSymb />}
                        {showConf
                            && <EmailConfPopup isMobile={true} name={authState?.name} setShowConf={setShowConf} />}
                    </div>
                </div>

                <Slide
                    direction="right"
                    in={menuOpen}
                    // mountOnEnter
                    // unmountOnExit
                    easing={{
                        enter: "cubic-bezier(0.5, 1, 0.3, 0.5)",
                    }}
                    exit={false}
                >
                <Box>
                <div
                    ref={refMenu}
                    className={styles.menu}
                >
                    {!authState &&
                        <div className={styles.auth}>
                            <div className={styles.auth_top}>
                                <div className={styles.auth_top_symbol}>
                                    <AuthSymb />
                                </div>
                                <div className={styles.auth_top_text}>
                                    Get the full experience
                                </div>
                            </div>
                            <div className={styles.auth_middle}>
                                Track prices, organize travel plans and access your personal Dashboard with your CuddlyNest account
                            </div>
                            <div className={styles.auth_bottom}>
                                <button
                                    type='button'
                                    onClick={() => {
                                        setAuthState({
                                            isAuthPromptOpen: true,
                                            authPromptType: AuthenticationPromptType.Login
                                        });
                                    }}
                                    className={clsx('js-login-prompt-trigger', styles.auth_bottom_login)}
                                >
                                    Login
                                </button>
                                <button
                                    type='button'
                                    onClick={() => {
                                        setAuthState({
                                            isAuthPromptOpen: true,
                                            authPromptType: AuthenticationPromptType.Signup
                                        });
                                    }}
                                    className={clsx('js-signup-prompt-trigger', styles.auth_bottom_signup)}
                                >
                                    Sign up
                                </button>
                            </div>
                        </div>}


                    {
                     authState && <SwitcherComponent className="mt-14 mb-14" noRedirect />
                    }
                    <ul className={styles.menu_items}>

                        <li className={styles.menu_item}>
                            <button
                                type='button'
                                className={styles.currency_btn}
                                onClick={openCurrencySelectionScreen}
                            >
                                <span className={styles.menu_item_symb}><menuSymbols.CurrencySymb /></span>
                                <span className={styles.menu_item_text}>Currency</span>
                                <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                            </button>
                            {isCurrencyScreenOpen &&
                                <Portal>
                                    <CurrencyBox
                                        state={setOpenCurrencySelectionScreen}
                                        info={currencies}
                                    />
                                </Portal>}
                        </li>

                        <GeneralMobileSwitcherRoutes />

                        <li className={styles.menu_item}>
                            <Link href={constants.help_route}>
                                <a>
                                    <span className={styles.menu_item_symb}><menuSymbols.FaqSymb /></span>
                                    <span className={styles.menu_item_text}>FAQ & Help Center</span>
                                    <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                                </a>
                            </Link>
                        </li>
                        <li className={styles.menu_item}>
                            <Link href={constants.contact_us_route}>
                                <a>
                                    <span className={styles.menu_item_symb}><menuSymbols.ContactUsSymb /></span>
                                    <span className={styles.menu_item_text}>Contact us</span>
                                    <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                                </a>
                            </Link>
                        </li>
                        <li className={styles.menu_item}>
                            <Link href={constants.privacy_policy_route}>
                                <a>
                                    <span className={styles.menu_item_symb}><menuSymbols.PrivacySymb /></span>
                                    <span className={styles.menu_item_text}>Privacy policy</span>
                                    <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                                </a>
                            </Link>
                        </li>
                        <li className={styles.menu_item}>
                            <Link href={constants.terms_and_conditions_route}>
                                <a>
                                    <span className={styles.menu_item_symb}><menuSymbols.TermsSymb /></span>
                                    <span className={styles.menu_item_text}>Terms and conditions</span>
                                    <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                                </a>
                            </Link>
                        </li>
                        {authState &&
                            <li className={styles.menu_item}>
                                <button
                                    type='button'
                                    className={styles.logout_btn}
                                    onClick={handleLogout}
                                >
                                    <span className={styles.menu_item_symb}><menuSymbols.LogoutSymb /></span>
                                    <span className={styles.menu_item_text}>Sign out</span>
                                    <span className={styles.menu_item_arrow}><menuSymbols.ArrowSymb /></span>
                                </button>
                            </li>}
                    </ul>

                    <div className={styles.menu_line}></div>
                    <div className={isIosDevice ? styles.menu_footer_ios : styles.menu_footer }>
                        Travel is for everyone<span className={styles.menu_footer_dot}>.</span>
                    </div>
                </div>
                </Box>
                </Slide>

            </div>
               {authOpen &&
                    <Drawer
                        anchor='left'
                        open={authOpen}
                        PaperProps={{
                            sx: {
                                width: '100%',
                                overflow: 'hidden'
                            }
                        }}
                        ModalProps={{
                            keepMounted: true
                        }}
                    >
                            <Authentication />
                    </Drawer>
                }
        </div>
    );
};

export default MobileHeader;
