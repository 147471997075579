import React, { memo, useCallback, useMemo, useEffect, useState, forwardRef } from 'react';
import moment from 'moment';
import DatesIcon from '@/components/common/icons/Dates';
import InputSlot from '../InputSlot';
import LabelledInputWrapper from '../LabelledInputWrapper';
import DateRangeModel from './DateRangeModel';
import StepEnum from '../enum';
import DatesNew from '@/components/common/icons/DatesNew';
import LengthOfStayNotification from '@/containers/newHomePage/components/Extra/LengthOfStayNotification';

const DateRangeInput = ({ value, isActive, onChange, setCurrentStep }, ref) => {
    const [startDate, endDate] = [moment(value.startDate), moment(value.endDate)];

    const isModelEmpty = useMemo(() => Object.values(value).every(date => !Boolean(date)), [value]);

    const onClear = useCallback(() => {
        onChange(new DateRangeModel);
    }, [onChange]);

    const onActivateStep = useCallback(() => {
        setCurrentStep(StepEnum.DATES);
    }, [setCurrentStep]);

    return (
        <InputSlot
            ref={ref}
            className="omnisearch_content_input-area_dates-input"
            prependIcon={<DatesNew className="omnisearch_content_input-area_input-icon" />}
            isActive={isActive}
            onClear={onClear}
            onChangeStep={onActivateStep}
            isEmpty={isModelEmpty}
        >
            <LabelledInputWrapper
               label="Dates">
                 <div className="omnisearch_content_input-area_input_continer">
                    <div
                        test-id='hp_guests'
                        className="omnisearch_content_input-area_input_container--who">Dates</div></div>
                    {isModelEmpty ? 
                    <div className="omnisearch_content_input-area_input_continer">
                    <div
                        test-id='hp_guests'
                        className="omnisearch_content_input-area_input_container--addguest">Add dates</div>
                    </div>
                        :(
                    <div className="omnisearch_content_input-area_input--dirty">
                        {startDate.isValid()
                            ? <span className="omnisearch_content_input-area_dates-input_valid">{startDate.format('DD MMM')}</span>
                            : 'Check-in'}
                        <span className="omnisearch_content_input-area_dates-input_valid"> - </span>
                        {endDate.isValid()
                            ? <span className="omnisearch_content_input-area_dates-input_valid">{endDate.format('DD MMM')}</span>
                            : 'Check-out'}
                    </div>
                )}
            </LabelledInputWrapper>
        </InputSlot>
    );
};

export default memo(forwardRef(DateRangeInput));
