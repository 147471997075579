import React, { memo, useCallback } from 'react';
import pluralize from 'pluralize';
import PersonIcon from '@/components/common/icons/Person';
import InputSlot from '../InputSlot';
import LabelledInputWrapper from '../LabelledInputWrapper';
import GuestModel from './GuestModel';
import StepEnum from '../enum';
import PersonNew from '@/components/common/icons/PersonNew';

const GuestInput = ({ value, isActive, onChange, setCurrentStep }) => {

    const onClear = useCallback(() => {
        onChange(new GuestModel({ isVisited: true }));
    }, [onChange]);

    const onActivateStep = useCallback(() => {
        setCurrentStep(StepEnum.GUESTS);
        onChange(state => new GuestModel({
            ...state,
            isVisited: true
        }));
    }, [setCurrentStep, onChange]);

    return (
        <InputSlot
            className="omnisearch_content_input-area_guest-input"
            prependIcon={<PersonNew className="omnisearch_content_input-area_guest-input_icon" />}
            isActive={isActive}
            onClear={onClear}
            onChangeStep={onActivateStep}
            isEmpty={value.totalCount === 1}
        >
            <LabelledInputWrapper
                label="Guests"
            >
                 <div className="omnisearch_content_input-area_input_continer">
                    <div
                            test-id='hp_guests'
                            className="omnisearch_content_input-area_input_container--who">Who</div>
                            </div>
                {value.isVisited ? (
                    <div className="omnisearch_content_input-area_input--dirty">
                        {pluralize('guest', value.totalCount, true)} · {pluralize('room', value.roomCount, true)}
                    </div>
                ) : (
                    <div className="omnisearch_content_input-area_input_continer">
                        <div
                            test-id='hp_guests'
                            className="omnisearch_content_input-area_input_container--addguest">Add guests</div>
                    </div>
                )}
            </LabelledInputWrapper>
        </InputSlot>
    );
};

export default memo(GuestInput);
