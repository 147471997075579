import React, { forwardRef, memo } from 'react';
import clsx from 'clsx';
import CloseIcon from '@/components/common/icons/Close';

const InputSlot = ({
    className,
    prependIcon,
    isActive,
    isEmpty,
    children,
    onClear,
    onChangeStep
}, ref) => (
    <div
        ref={ref}
        className={clsx('omnisearch_content_input-area_input-slot', {
            'omnisearch_content_input-area_input-slot--active': isActive
        }, className)}
        onClick={onChangeStep}
    >
        {/* {prependIcon} */}
        {children}
        {!isEmpty && isActive && (
            <button
                className="omnisearch_content_input-area_input-slot_close-btn"
                onClick={onClear}
                type='button'
            >
                <CloseIcon className="omnisearch_content_input-area_input-slot_close-btn_icon" />
            </button>
        )}
    </div>
);

export default memo(forwardRef(InputSlot));
